<template>
  <div class="d-flex">
    <v-btn
        v-if="statue.action.commentable"
        fab
        dark
        x-small
        color="deep-purple accent-1"
        class="mr-2"
        @click="showDetail"
    >
      <v-icon dark>mdi-comment-multiple-outline</v-icon>
    </v-btn>
    <v-btn
        v-if="statue.action.editable"
        fab
        dark
        x-small
        color="warning"
        class="mr-2"
        @click="edit"
    >
      <v-icon dark>mdi-pencil-box-outline</v-icon>
    </v-btn>
    <v-btn
        v-if="statue.action.deletable"
        fab
        dark
        x-small
        color="error"
        class="mr-2"
        @click="deleteOrder"
    >
      <v-icon dark>mdi-delete-empty-outline</v-icon>
    </v-btn>

  </div>
</template>

<script>
import db from "@/plugins/db";

export default {
  name: "actions",
  props: {
    order: {
      type: Object,
      default: () => {
        return {
          status: ''
        }
      }
    }
  },
  methods: {
    showDetail() {
      this.$router.push(`/service/order/${this.order._id}`)
    },
    edit() {
      console.log(this.order._id)
    },
    deleteOrder() {
      db.collection('serviceOrders')
          .doc(this.order._id)
          .remove()
          .then(() => {
            this.$emit('removed')
          })
    }
  },
  computed: {
    statue() {
      if (this.order.status) {
        return this.$store.state.serviceSettings.serviceOrderStatus[this.order.status]
      }
      return {
        action: {
          deletable: true
        }
      }
    }
  }
}
</script>

<style scoped>

</style>