<template>
  <resizeable-page>
    <template #header>
      <v-text-field
          v-model="search"
          outlined
          @keydown.enter="submit"
          @keydown.e.esc="clear"
          clear-icon="mdi-close-thick"
          clearable
          @click:clear="clear"
      >
        <template v-slot:label>
          <v-icon style="vertical-align: middle">
            mdi-magnify
          </v-icon>
          输入<strong>工单号、合同号、手机号、姓名</strong>按回车查询(Esc清除)
        </template>
      </v-text-field>
    </template>
    <template #toolbar>
      <v-toolbar dense flat class="flex-grow-0 flex-shrink-0">
        <button-new-service-order @created="getServiceOrder(1)" ></button-new-service-order>
      </v-toolbar>
    </template>
    <template #filter></template>
    <template #table="tableProps">
      <v-data-table
          :headers="headers"
          :items="serviceOrders"
          calculate-widths
          :items-per-page="limit"
          :page="page"
          item-key="_id"
          :search="search"
          dense
          fixed-header
          :height="tableProps.tableHeight"
          :server-items-length="orderCount"
          @update:page="getServiceOrder"
          :footer-props="footerOption"
      >
        <template #item.status="{ item }">
          <serviceOrderStatus :status="item.status"/>
        </template>
        <template #item.code="{ item }">
          <router-link :to="`/service/order/${item._id}`"> {{ item.code }}</router-link>
        </template>
        <template #item.trouble="{ item }">
          <span class="d-inline-block text-truncate"
                style="max-width: 150px;"
          >
            {{ item.trouble }}
          </span>
        </template>
        <template #item.buttons="{ item }">
          <actions :order="item" @removed="getServiceOrder(1)"></actions>
        </template>
      </v-data-table>
    </template>
  </resizeable-page>
</template>

<script>
import resizeablePage from "@/components/resizeable-page";
import db from "@/plugins/db";
import actions from "@/views/service/component/actions";
import serviceOrderStatus from "@/views/service/component/serviceOrderStatus.vue";
import buttonNewServiceOrder from "@/views/service/component/buttonNewServiceOrder";

export default {
  components: {
    resizeablePage,
    actions,
    serviceOrderStatus,
    buttonNewServiceOrder,
  },
  data() {
    return {
      headers: [
        {
          text: '操作',
          align: 'start',
          sortable: false,
          value: 'buttons',
          width: 100
        },
        {
          text: '工单编号',
          align: 'start',
          sortable: false,
          value: 'code',
          width: 150
        },
        {
          text: '工单状态',
          align: 'start',
          sortable: false,
          value: 'status',
          width: 200
        },
        {
          text: '申请时间',
          align: 'start',
          sortable: false,
          value: 'createDate',
          width: 150
        },
        {
          text: '客户',
          align: 'start',
          sortable: false,
          value: 'customerName',
          width: 100
        },
        {
          text: '电话',
          align: 'start',
          sortable: false,
          value: 'customerPhone',
          width: 100
        },
        {
          text: '合同号',
          align: 'start',
          sortable: false,
          value: 'contractCode',
          width: 100
        },
        {
          text: '项目',
          align: 'start',
          sortable: false,
          value: 'ProjectName',
          width: 100
        },
        {
          text: '品类',
          align: 'start',
          sortable: false,
          value: 'category',
          width: 100
        },
        {
          text: '品牌',
          align: 'start',
          sortable: false,
          value: 'brand',
          width: 100
        },
        {
          text: '问题描述',
          align: 'start',
          sortable: false,
          value: 'trouble',
          cellClass: ['col-2', 'text-truncate'],
          width: 100
        },
        {
          text: '完成时间',
          align: 'start',
          sortable: false,
          value: 'finishDate',
          width: 100
        }
      ],
      serviceOrders: [],
      search: '',
      limit: 20,
      page: 1,
      orderCount: 0,
      footerOption: {
        'items-per-page-options': [5, 10, 15, 20, -1]
      }
    }
  },
  created() {
    this.getServiceOrder(1)
  },
  methods: {
    getServiceOrder(page) {
      let limit = this.limit
      let query = db.collection('serviceOrders')
      if (this.search){
        const _ = db.command;
        let params = _.or(
            {
              code:this.search
            }
            ,
            {
              customerPhone:parseInt(this.search)
            },
            {
              contractCode:this.search
            },
            {
              customerName:this.search
            }
        )
        console.log(params)
        query = query.where(params)
      }

      query
          .count()
          .then((res) => {
            this.orderCount = res.total
          });
      query
          .skip((page - 1) * limit)
          .limit(this.limit)
          .orderBy('_id', 'desc')
          .get()
          .then((res) => {
            this.serviceOrders = res.data
          });
    },
    filterOnlyCapsText() {

    },
    submit() {
      this.getServiceOrder(1)
    },
    clear(){
      this.search = ''
      this.getServiceOrder(1)
    }
  }
}
</script>

<style scoped>
</style>