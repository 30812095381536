var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('resizeable-page',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-text-field',{attrs:{"outlined":"","clear-icon":"mdi-close-thick","clearable":""},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"e",undefined,$event.key,undefined)&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.clear.apply(null, arguments)}],"click:clear":_vm.clear},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticStyle:{"vertical-align":"middle"}},[_vm._v(" mdi-magnify ")]),_vm._v(" 输入"),_c('strong',[_vm._v("工单号、合同号、手机号、姓名")]),_vm._v("按回车查询(Esc清除) ")]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"toolbar",fn:function(){return [_c('v-toolbar',{staticClass:"flex-grow-0 flex-shrink-0",attrs:{"dense":"","flat":""}},[_c('button-new-service-order',{on:{"created":function($event){return _vm.getServiceOrder(1)}}})],1)]},proxy:true},{key:"filter",fn:function(){return undefined},proxy:true},{key:"table",fn:function(tableProps){return [_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.serviceOrders,"calculate-widths":"","items-per-page":_vm.limit,"page":_vm.page,"item-key":"_id","search":_vm.search,"dense":"","fixed-header":"","height":tableProps.tableHeight,"server-items-length":_vm.orderCount,"footer-props":_vm.footerOption},on:{"update:page":_vm.getServiceOrder},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('serviceOrderStatus',{attrs:{"status":item.status}})]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/service/order/" + (item._id))}},[_vm._v(" "+_vm._s(item.code))])]}},{key:"item.trouble",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"150px"}},[_vm._v(" "+_vm._s(item.trouble)+" ")])]}},{key:"item.buttons",fn:function(ref){
var item = ref.item;
return [_c('actions',{attrs:{"order":item},on:{"removed":function($event){return _vm.getServiceOrder(1)}}})]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }