<template>
  <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="dialog"
  >
    <template #activator="{ on, attrs }">
      <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
      >新增工单
      </v-btn>
    </template>
    <template #default>
      <v-card>
        <v-toolbar
            color="primary"
            dark
        >Opening from the bottom
        </v-toolbar>
        <v-card-text>
          <div class="text-h2 pa-12">Hello world!</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
              text
              @click="newServiceOrder()"
          >Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import db from "@/plugins/db";
export default {
  name: "buttonNewServiceOrder",
  data(){
    return {
      dialog:false
    }
  },
  methods:{
    newServiceOrder(){
      db.collection('serviceOrders')
      .add({
        code:new Date().getTime(),
        createDate:new Date(),
      })
      .then((s)=>{
        this.dialog = false
        this.$emit('created',s)
        console.log(this.dialog)
      })
    }
  }

}
</script>

<style scoped>

</style>